import React, { useState } from 'react'

import * as HighCharts from 'highcharts'
import { HighchartsReact } from 'highcharts-react-official'

export default function TesteBox () {

  const opcoes = {
    chart: {
      type: 'bar'
    },
    title: {
      text: 'Fruit Consumption'
    },
    xAxis: {
      categories: ['Apples', 'Bananas', 'Oranges']
    },
    yAxis: {
      title: {
        text: 'Fruit eaten'
      }
    },
    series: [{
      name: 'Jane',
      data: [1, 0, 4]
    }, {
      name: 'John',
      data: [5, 7, 3]
    }]
  }



  return (
    <>
      <HighchartsReact
        highcharts={HighCharts}
        options={opcoes}
      />
    </>
  )
}