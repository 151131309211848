import React from 'react'

import { createBrowserRouter } from 'react-router-dom'
import ErroAplicacao from '../Layout/ErroAplicacao'
import Layout from '../Layout/Layout'
import ExemploMenu from '../exemplo_apagar/menu'
import TesteBox from '../exemplo_apagar/TesteHighCharts'
import Produtos from '../View/Crud/Produtos'
import Usuarios from '../View/Crud/Sistema/Usuarios'
import Grupos from '../View/Crud/Sistema/Grupos'
import Regionais from '../View/Crud/Regionais'
import Unidades from '../View/Crud/Unidades'
import SetUnidade from '../View/Controller/SetUnidade'
import Logout from '../View/Controller/Logout'
import AlterarSenha from '../View/Controller/AlterarSenha'
import Clientes from '../View/Crud/Clientes'
import ContaCorrente from '../View/Crud/ContaCorrente'
import Saldos from '../View/Relatorios/Saldos'
import Produtividade from '../View/Relatorios/Produtividade'
import ContratosEmitidos from '../View/Relatorios/ContratosEmitidos'
import CoraOnLine from '../View/Relatorios/CoraOnLine'
import Parcelas from '../View/Relatorios/Parcelas'
import MovimentacaoFinanceira from '../View/Relatorios/MovimentacaoFinanceira'
import RelContaCorrente from '../View/Relatorios/RelContaCorrente'
import Contratos from '../View/Crud/Contratos'

export const router = createBrowserRouter( [
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErroAplicacao />,
    children: [
      {
        path: "Contratos",
        element: <Contratos />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Unidades",
        element: <Unidades />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Grupos",
        element: <Grupos />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Clientes",
        element: <Clientes />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "ContaCorrente",
        element: <ContaCorrente />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Produtos",
        element: <Produtos />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Regionais",
        element: <Regionais />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "SetUnidade",
        element: <SetUnidade />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Logout",
        element: <Logout />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "AlterarSenha",
        element: <AlterarSenha />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Usuarios",
        element: <Usuarios />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Saldos",
        element: <Saldos />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "relProdutividade",
        element: <Produtividade />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "relContratosEmitidos",
        element: <ContratosEmitidos />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "relCoraOnLine",
        element: <CoraOnLine />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "relContaCorrente",
        element: <RelContaCorrente />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "relParcelas",
        element: <Parcelas />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "relMovimentacaoFinanceira",
        element: <MovimentacaoFinanceira />,
        errorElement: <ErroAplicacao />
      }
    ]
  },
  {
    path: "/ErroAplicacao",
    element: <ErroAplicacao />
  },
  {
    path: "/ExemploMenu",
    element: <ExemploMenu />
  },
  {
    path: "/TesteBox",
    element: <TesteBox />
  }

  /*
    {
      path: "/",
      element: <LayOut />,
      errorElement: <ErroAplicacao />,
      children: [
        {
          path: "cadastrocliente/:idCliente",
          element: <CadastroCliente />,
          errorElement: <ErroAplicacao />
        },
        {
          path: "cadastrofornecedor",
          element: <CadastroFornecedor />,
          errorElement: <ErroAplicacao />
        }
      ],
    },
    {
      path: "*",
      element: <ErroNavegacao />
    }
    */
] );